<template>
<div>
  <div v-if="vrijepanden" id="carouselPanden" class="carousel slide"  data-bs-interval="false" data-aos="fade-up">
    <div class="carousel-inner">
      <template v-for="(item, index) in vrijepanden" :key="index">
        <div class="carousel-item" v-bind:class="{'active' : index == 0}">
          <div class="row align-item-center">
            <Pand v-for="(subitem, index) in item" :key="index" :pand="subitem"/>
          </div>
        </div>
      </template>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselPanden" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next margin-right" type="button" data-bs-target="#carouselPanden" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</div>
</template>

<script>

import Pand from '../Panden/Pand.vue'

//importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'

export default {
  name: 'Usp',
  components : {
      Pand
  },
  mounted(){
    var myCarousel = document.querySelector('#myCarousel');
    new Bootstrap.Carousel(myCarousel)
  },
  computed:{
    vrijepanden(){
      if(this.$store.state.pandenStore && this.$store.state.pandenStore.panden && this.$store.state.pandenStore.panden.items)
        return this.$store.state.pandenStore.panden.items;
      else
        return null;
    }
  },
  async created(){
    await this.$store.dispatch('pandenStore/getPanden');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .lanceAnim{
    margin-top: 200px;
    margin-bottom : 200px;
}
</style>