<template>
<div>
    <div v-if="AllActies" id="carouselActies" class="carousel slide"  data-bs-interval="false" data-aos="fade-up">
      <div class="carousel-inner">
        <template v-for="(item, index) in AllActies" :key="index">
          <div class="carousel-item" v-bind:class="{'active' : index == 0}">
            <div class="row align-item-center">
              <Actie v-for="(subitem, index) in item" :key="index" :actie="subitem"/>
            </div>
          </div>
        </template>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselActies" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next margin-right" type="button" data-bs-target="#carouselActies" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
  </div>
</div>
</template>

<script>

import Actie from './Actie.vue'

//importing bootstrap 5
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'

export default {
  name: 'Acties',
  components : {
      Actie
  },
  mounted(){
    var myCarousel = document.querySelector('#myCarousel');
    new Bootstrap.Carousel(myCarousel)
  },
  computed:{
    AllActies(){
      if(this.$store.state.actieStore && this.$store.state.actieStore.AllActiesHome && this.$store.state.actieStore.AllActiesHome.items)
        return this.$store.state.actieStore.AllActiesHome.items;
      else
        return null;
    }
  },
  async created(){
    await this.$store.dispatch('actieStore/getAllHomeActies');
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .lanceAnim{
    margin-top: 200px;
    margin-bottom : 200px;
}
</style>