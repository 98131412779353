<template>
  <h2>{{raad.raad.title}}</h2>
  <p>
    {{raad.raad.text}}
  </p>
  <div v-if="raad.raad && raad.raad.RaadItems && raad.raad.RaadItems && raad.raad.RaadItems.length > 0" :id="'carouselRaad' + raad.raad.RaadItems[0].airid" class="carousel slide"  data-bs-interval="false" data-aos="fade-up">
    <div class="carousel-inner">
      <template v-for="(item, index) in raad.raad.RaadItems" :key="index">
        <div class="carousel-item" v-bind:class="{'active' : index == 0}">
          <div class="row align-item-center">
              <RaadItem 
              v-for="subitem in item" 
              :key="subitem" 
              :items="subitem" 
              @clickedInd="clickedInd(subitem.Id)" 
              @clickedViewInd="clickedViewInd(subitem.Id)"/>
          </div>
        </div>
      </template>
    </div>
    <button class="carousel-control-prev " type="button" :data-bs-target="'#carouselRaad' + raad.raad.RaadItems[0].airid" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next margin-right" type="button" :data-bs-target="'#carouselRaad' + raad.raad.RaadItems[0].airid" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>
</template>



<script>
/* eslint-disable vue/no-unused-components */
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'

import RaadItem from './RaadItem.vue'

export default {
  name: 'Raad',
  data: function(){
    return{
      raad: this.prop
    }
  },
  props:{
    prop: null
  },
  components:{
    RaadItem,
  }, 
  methods:{
  },
  computed:{
    log(){
      return this.$store.state.authenticationStore.user;
    }   
  },
  mounted(){
    var myCarousel = document.querySelector('#myCarousel')
    new Bootstrap.Carousel(myCarousel)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.voors-enter-from,.voors-leave-to{
  opacity:0;
}

.voors-enter-to,.voors-leave-from{
  opacity:1;
}

.voors-enter-active, .voors-leave-active{
  transition:all 0.4s ease-out;
}
</style>
