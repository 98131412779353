<template>
    <div class="col-12 col-md-4 text-center p-5" v-if="items">
        <div class="card tumiDeco">
            <router-link :to="{name:'actie', params:{ id: items.airid }}">
                <div class="card-body">
                    <h3>{{items.einde}}</h3>
                    <h3>{{items.aanvang}}</h3>
                    <h4 v-if="items.benaming" >{{items.benaming}}</h4>
                </div>
            </router-link>
        </div>
    </div>
</template>


<script>
export default {
    name: 'RaadItem',
    props : {
        items:{},
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.tumiDeco{
    background-color:#5ea7c4; 
    color:black;
    text-decoration:none;
}

a, a:hover{
    color:black;
    text-decoration: none;
}

.tumiDeco:hover{
    background-color:#76cff2; 
    color:white;
    text-decoration:none;
}

.btn{
    margin-top:20px;
    font-size:1rem;
    padding:7px;
}

.buttonCol{
    cursor: pointer;
    border-bottom:solid 1px lightgrey;
    color:grey;
    background-color:white;;
    font-size: 1.15rem;
    transition: color 0.4s, background-color 0.4s;
     -moz-transition: color 0.4s, background-color 0.4s; /* Firefox 4 */
     -webkit-transition: color 0.4s, background-color 0.4s; /* Safari and Chrome */
     -o-transition: color 0.4s, background-color 0.4s;
}

.buttonCol:hover{
    color:black;
    background-color:#f5c45d;
}

</style>
