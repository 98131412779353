<template>
  <CarousselAuto v-if="waarden" :doelstellingen="waarden" />
</template>

<script>
import CarousselAuto from '../../components/Widgets/CarousselCTAItem.vue'

export default {
  name: 'Waarden',
  components: {
    CarousselAuto
  },
  data:function(){
    return{
      waarden: null
    }
  }
  ,computed:{
    waardenOri(){
        return this.$store.state.uspStore.waarden.items
    }
  },
  async created(){
    await this.$store.dispatch('uspStore/getWaarden');
  },
  watch:{
    waardenOri(e){
      if(e)
      {
        this.waarden = [];

        e.forEach(element => {
          var deux = [];
          
          element.forEach(el2=>{
            if(el2)
              deux.push({title : el2.waardeN_BENAMING, text: el2.waardeN_OMSCHRIJVING });
            else
              deux.push({});
          })

          this.waarden.push(deux);
        });
      }

    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>