<template>
  <h2 class="mt-5" id="debutTeam">{{prop.titleTeam}}</h2>
  
  <p>
    {{prop.textTeam}}
  </p>

  <div v-if="leden" id="carouselLeden" class="carousel slide"  data-bs-interval="false" data-aos="fade-up">
    <div class="carousel-inner">
      <template v-for="(item, index) in leden" :key="index">
        <div class="carousel-item" v-bind:class="{'active' : index == 0}">
          <div class="row align-item-center">
              <LeedItem v-for="subitem in item" :key="subitem" :items="subitem"/>
          </div>
        </div>
      </template>
    </div>
    <button class="carousel-control-prev " type="button" data-bs-target="#carouselLeden" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Previous</span>
    </button>
    <button class="carousel-control-next margin-right" type="button" data-bs-target="#carouselLeden" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden">Next</span>
    </button>
  </div>

  <Formulier :form="form"/>

</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'

import LeedItem from './LeedItem.vue'
import Formulier from '../Widgets/Formulier.vue'
import {Form} from '../../helpers/Classes'
import {FieldForm} from '../../helpers/Classes'

import {shadeColor} from '../../helpers/handleResponse'

export default {
  name: 'Team',
  data: function(){
    return{
      modal: false,
      form: null,
      color:"#5ea7c4",
      colorDarker: null,
      colorLight: null
    }
  },
  props:{
    prop:null
  },
  components:{
    LeedItem,
    Formulier
  }, 
  methods:{

  },
  computed:{
    leden(){
      return this.$store.state.ledenStore.leden.items;
    },
    log(){
      return this.$store.state.authenticationStore.user;
    }   
  },
  mounted(){
    var myCarousel = document.querySelector('#myCarousel')
    new Bootstrap.Carousel(myCarousel)
  },
  created(){
    this.$store.dispatch('ledenStore/getLeden');
    this.form = new Form(
      "sendKandidaat",
      "Stel je kandidaat voor het SKWINKELTeam",
      [
        new FieldForm(this.log ? this.log.organisatie.organisatieS_NAAM : "", "Naam organisatie", "", false),
        new FieldForm("", "Persoon", "", false),
        new FieldForm("", "Motivering", "Motivering is een verplicht veld", true)
      ], 
      "Bedankt voor jouw kandidatuur. Je wordt eerstdaags gecontacteerd.", 
      this.prop.title, 
      this.prop.text,
      this.color)
    
    this.colorDarker = shadeColor(this.color, -40);
    this.colorLight = shadeColor(this.color, 100);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
