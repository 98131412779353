<template>
    <div class="col-12 col-md-4 text-center p-5" v-if="items">
        <div class="card">
            <img :src="JSON.parse(items.image)[0].url" class="img-fluid"/>
            
            <div class="card-body">
                <h2 class="mt-5">{{JSON.parse(items.cta)[0]}}</h2>
                <h3>{{JSON.parse(items.title)[0]}}</h3>
                <p>{{items.text}}</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LeedItem',
    props : {
        items:{}
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.btn{
    margin-top:20px;
}

</style>
