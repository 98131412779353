<template>
    <div v-if="actie" class="col-12 col-md-4 text-center p-5">
        <router-link :to="{ name: 'actie', params: { id: actie.actieS_AIRID }}" class="card mb-7 p-5" href="#!">
            <!-- Image -->
        <img v-if="actie && actie.actieS_THEMABEELDENT600TT600 && JSON.parse(actie.actieS_THEMABEELDENT600TT600).length > 0" :src="JSON.parse(actie.actieS_THEMABEELDENT600TT600)[0].url" class="card-img-top">
            <!-- Body -->
            <div class="card-body">
                <h3 class="mt-6 mb-1">
                    <span v-if="actie">{{actie.actieS_BENAMING}}</span>
                </h3>

                <p class="text-muted mb-3 ">
                    <span v-if="actie">{{actie.actieS_AANVANGTACTIEPERIODE}} - {{actie.actieS_EINDETACTIEPERIODE}}</span>
                </p>

                <p class="mt-3">
                    {{actie.actieS_KORTETOMSCHRIJVING}}
                </p>
            </div>
        </router-link>  
    </div>
</template>

<script>
export default {
    name: 'actie',
    props : {
        actie:{},
        index: null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.btn{
    margin-top:20px;
}

.cardactie{
    background-color: #daddea;
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(0,0,0,0.2);
}

a{
    color:rgb(75, 75, 75)
}

a:hover{
    text-decoration: none;
    color:black;
}

</style>
