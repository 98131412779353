<template>

  <div v-if="pand && pand.bedrijfsruimteS_ADRES">

    <div class="container mt-7">
      <img :src="image" class="img-fluid mb-5" />
    </div>

      <div class="container">
        <div id="carouselExampleIndicators" class="carousel slide" data-bs-interval="false">
          <div class="carousel-inner">
            <template v-for="(item, index1) in images" :key="index1">
              <div class="carousel-item" v-bind:class="{'active' : index1 == 0}">
                <div class="row">
                  <div v-for="(item, index2) in item" :key="index2" class="col-3 d-flex flex-wrap align-items-center">
                    <img :src="item" class="img-fuild mw-100 thumbNail border-5 border-primary " @click="changer(index1, index2)"/>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
    </div>


    <div class="container">
      <span v-if="pand.bedrijfsruimteS_STATUS" class="float-start badge rounded-pill bg-success p-3 me-5 mt-2" data-aos-delay="300">
        <span class="h5">{{pand.bedrijfsruimteS_STATUS}}</span>
      </span>

      <h1 class="display-3 mt-5">{{pand.bedrijfsruimteS_ADRES}}</h1>
      
      <h2 class="mb-2">{{pand.bedrijfsruimteS_GEMEENTE}} {{pand.bedrijfsruimteS_POSTCODE}}</h2>
      
      <p class="p-5">
        {{pand.bedrijfsruimteS_INFO}}
      </p>

      <ul class="p-5">
        <li v-if="pand.bedrijfsruimteS_NETTOTWVO"><b>Netto oppervlakte:</b> {{pand.bedrijfsruimteS_NETTOTWVO}} m2</li>
        <li v-if="pand.bedrijfsruimteS_BRUTOTWVO"><b>Bruto oppervlakte:</b> {{pand.bedrijfsruimteS_BRUTOTWVO}} m2</li>
        <li v-if="pand.bedrijfsruimteS_BESCHIKBAAR"><b>Beschikbaar vanaf:</b> {{pand.bedrijfsruimteS_BESCHIKBAAR}}</li>
        <li v-if="pand.bedrijfsruimteS_PLAN">
          <b>
            <a v-if="JSON.parse(pand.bedrijfsruimteS_PLAN).length > 0" target="_blank" :href="JSON.parse(pand.bedrijfsruimteS_PLAN)[0]"> 
              Plan
            </a>
          </b>
        </li>
      </ul>



    </div>
  </div>
    <div v-if="pand.location">
      <GMapMap
          :center="pand.location"
          :zoom="15"
          map-type-id="terrain"
          style="width: 100%; height: 400px">
          <GMapMarker :position="pand.location" />
      </GMapMap>
    </div>

      <!-- {{pand}} -->

      <!-- {{pand.bedrijfsruimteS_DORPSKERN}}
      {{pand.bedrijfsruimteS_ZONE}}
      {{pand.bedrijfsruimteS_AFBEELDINGEN}}
      {{pand.bedrijfsruimteS_PLAN}}
      {{pand.bedrijfsruimteS_BEMIDDELAAR}} -->

</template>

<script>

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import * as Bootstrap from 'bootstrap'

export default {
  name: 'BannerPandOne',
  props:{
    pand: null,
  },
  data: function(){
    return{
      center: null,
      markers: [],
      images:[],
      image:""
    }
  },
  methods:{
    changer(x, y){
      this.image = this.images[x][y];
    }
  },
  created(){

    console.log(this.pand.bedrijfsruimteS_AFBEELDINGENT2080X1248);

    if(this.pand.bedrijfsruimteS_AFBEELDINGENT2080X1248 && JSON.parse(this.pand.bedrijfsruimteS_AFBEELDINGENT2080X1248).length > 0)
    {
      var arr = JSON.parse(this.pand.bedrijfsruimteS_AFBEELDINGENT2080X1248);      

      for(let i = 0; i < arr.length; i += 4)
        this.images.push(arr.slice(i,i+4));

      this.image = this.images[0][0];
    }


    var myCarousel = document.querySelector('#carouselExampleIndicators')
       new Bootstrap.Carousel(myCarousel)
 }
}

</script>

<style scoped>
.thumbNail{
  cursor: pointer;
}


ul {
  list-style-type: none;
}

.bande{
  background-color: #e6eaf2;
}

</style>