<template>
  <div v-if="ruimte" :class="'col-12 col-md-4 p-5'">
    <div :class="{'card p-5': true , 'tumiSelected':sel}">
        <h3 class=" mb-1 text-center">
            <span v-if="ruimte && ruimte.benaming">{{ruimte.benaming}}</span>
        </h3>

        <p v-if="ruimte && ruimte.omschrijving" class="mt-3">
          {{ruimte.omschrijving}}
        </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RuimteCard',
  props:{
    filtre:null,
    ruimte:null,
    sel:null
  }
}
</script>

<style>
.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}

.card{
  cursor: pointer;
}

.tumiSelected{
  background-color: #86b4c6 !important;
}
</style>