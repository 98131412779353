<template>
  <section>
      <div class="row">
        <RuimteCard 
          v-for="i in 3" 
          :key="i"
          :sel="selected == ruimtes.listRuimtes[i - 1].ruimteS_AIRID"  
          :ruimte="{'benaming': ruimtes.listRuimtes[i - 1].ruimteS_BENAMING, 'omschrijving' : ruimtes.listRuimtes[i - 1].ruimteS_OMSCHRIJVING}" 
          @click="change(ruimtes.listRuimtes[i - 1].ruimteS_AIRID)"/>
      </div>
      <div class="row">
        <RuimteCard 
          v-for="i in 3" 
          :key="i"
          :sel="selected == ruimtes.listRuimtes[i + 2].ruimteS_AIRID" 
          :ruimte="{'benaming': ruimtes.listRuimtes[i + 2].ruimteS_BENAMING, 'omschrijving' : ruimtes.listRuimtes[i + 2].ruimteS_OMSCHRIJVING}" 
          @click="change(ruimtes.listRuimtes[i + 2].ruimteS_AIRID)"/>
      </div>
      <Usp :doelstellingen="zones" />
  </section>
</template>

<script>
import RuimteCard from './RuimteCard.vue'
import Usp from './../../Widgets/CarousselCTAItem.vue'
import {divisionEnChunk} from './../../../helpers/handleResponse'

export default {
  name: 'Ruimtes',
  props:{
    ruimtes:null
  },
  data:function(){
    return{
      zones: [],
      selected: null
    }
  },
  components:{
    RuimteCard,
    Usp
  },
  methods:{
    change(e){
      if(this.selected == e)
      {
        this.selected = null;
        this.concentrate(this.ruimtes.listZones)
      }
      else
      {
        this.selected = e;
        this.select(e);
      }
    },
    select(id){
      var selection = [];

      this.ruimtes.listZones.forEach(element=>{
        if(JSON.parse(element.zoneS_RUIMTE)[0] == id)
          selection.push(element);
      })

      this.concentrate(selection);
    },
    concentrate(e){
      this.zones = []
      var temp = []
      
      e.forEach(element => {
        temp.push({
          "titleButton": element.zoneS_BENAMING,
          "button" : "Bekijk de " + element.zoneS_TTBEDRIJFSRUIMTES + " ondernemingen binnen deze zone"
        })
      });


      this.zones = divisionEnChunk(temp,3);
    }
  },
  created(){
    this.concentrate(this.ruimtes.listZones);
  }
}
</script>

<style>
.colorTemplateBack{
  background-color: rgb(248, 248, 248);
}
</style>