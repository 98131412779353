<template>
<section class="position-relative pt-12 pt-md-8 mt-n11">
      <!-- Content -->
      <div class="container">
      <div class="row align-items-center text-center text-md-start">
          <div class="col-12 col-md-6">
            <!-- Image -->

            <img v-if="pand.bedrijfsruimteS_AFBEELDING" :src="pand.bedrijfsruimteS_AFBEELDING" class="img-fluid float-end mb-6 mb-md-0 aos-init aos-animate" data-aos="fade-right"/>
            <img v-if="!pand.bedrijfsruimteS_AFBEELDING" src="@/assets/1.jpg" alt="..." class="img-fluid float-end mb-6 mb-md-0 aos-init aos-animate" data-aos="fade-right">

          </div>

          <SousPand :pand="pand" :sens="'left'"/>

        </div>         
    </div>
  </section>


</template>

<script>
import SousPand from './SousPand.vue'


export default {
  name: 'Pandm',
      props : {
        pand: null
  },
  components: {
    SousPand
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

@media (min-width: 992px){
  .container, .container-lg, .container-md, .container-sm {
      max-width: 960px;
  }
}
@media (min-width: 768px){
  .container, .container-md, .container-sm {
      max-width: 720px;
  }
}

@media screen and (min-width: 1200px){
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1040px;
}
} 

.col-12{
  padding: 20px;
}

h1{
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 40px;
}

p{
  color: #869ab8!important;
}

</style>