<template>
  <div>
    <div class="container-fluid px-md-0">
      <div class="row justify-content-between gx-0 mx-md-0">
        <Menu :name="nameAccount" :paths="views" @switch="Switch" />
        <div class="col-12 col-md-9 p-5" style="background-color:#f2f2f2;">            
          <DynamicPage v-if="activeObject && !ShowAdressen && !ShowTeam" :desc="desc" :name="activeName" :objet="activeObject" :validate="validationError" @action="Act"/>
          <TableAdressen v-if="ShowAdressen" :id="id" />
          <TableTeam v-if="ShowTeam" :id="id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from '../../components/Portal/Menu.vue'
import DynamicPage from '../../components/Portal/DynamicPage.vue'
import TableAdressen from '../../components/Portal/TableAdressen.vue'
import TableTeam from '../../components/Portal/TableTeam.vue'

export default {
  name: 'Portal',
  components:{
    Menu,
    DynamicPage,
    TableAdressen,
    TableTeam
  },
  data:function(){
    return{
      views:[
        {
          name:"Account", 
          desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          path: "/portaal/GetAccount?id=", 
          pathSet: "/portaal/SetAccount",
          validation: function(obj){
            if((obj.wachtwoord && !obj.wachtwoord2) || (!obj.wachtwoord && obj.wachtwoord2))
              return "Om een nieuwe passwoord in te stellen zijn de twee wachtwoord velden verplicht."

            if((obj.wachtwoord && obj.wachtwoord2) && (obj.wachtwoord != obj.wachtwoord2))
              return "De wachtwoorden komen niet overeend."
            
            return null;
          },
        },
        {
          name:"Organisatie", 
          desc:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
          path: "/portaal/GetOrganisatie?id=", 
          pathSet: "/portaal/SetOrganisatie",
          validation: function(obj){
            if(obj != null)
              return null;
          },
        },        {
          name:"Vestigingsadressen"
        },
        {
          name:"Teamleden"
        }
      ],
      activeName: null,
      activeObject: null,
      desc:null,

      validationError : null,
      id: null,
      nameAccount:null,

      ShowAdressen: false,
      ShowTeam: false
    }
  },
  methods:{
    //WHEN SAVING DYNAMIC VIEW
    async Act(retour){
      //ERREUR BLANCO
      this.validationError = null;

      //CHECK VALIDATION OF VIEW
      const res = this.views.find(i=>i.name == this.activeName).validation(retour);
      
      if(retour['accountS_NAAM'])
        this.nameAccount = retour['accountS_NAAM'];

      if(retour['organisatieS_NAAM'])
        this.nameAccount = retour['organisatieS_NAAM'];

      //IF NOT VALIDATED SHOW ERROR
      if(res != null)
      {
        this.validationError = res;
        return;
      }

      //ELSE UPDATE DATABASE
      const pathSet = this.views.find(i=>i.name == this.activeName).pathSet;
      await this.$store.dispatch('dynamicStore/setDynamic', {pathSet, retour});
    },
    //GET DYNAMIC OBJECT
    async GetDynamic(path){
      //BLANCO DYNAMIC OBJECT
      this.activeObject = null;

      //GET PATH
      const pathComplet = path + this.id;
      //GET OBJECT FROM DATABASE
      await this.$store.dispatch('dynamicStore/getDynamic', pathComplet);
    
      //SET OBJECT
      this.activeObject = this.$store.state.dynamicStore[pathComplet];
    },
    async Switch(name){
      //IF NOT ADRESSES
      switch(name)
      {
        case "Vestigingsadressen":
          this.ShowTeam = false;
          this.ShowAdressen = true;
          break;
        
        case "Teamleden":
          this.ShowAdressen = false;
          this.ShowTeam = true;
          break;

        default:
          //HIDE ADRESSES AND TEAM
          this.ShowTeam = false;
          this.ShowAdressen = false;

          //KEEP NAME
          this.activeName = name;
          
          //GET VIEW AND LAUNCH GET OBJECT
          var view = this.views.find(i=>i.name == name);
          this.desc = view.desc;
          await this.GetDynamic(view.path);
          break;
      }
    }
  },
  computed:{
    log(){
      return this.$store.state.authenticationStore.login;
    }
  },
  async created(){
    if(this.log)
      this.id = this.$store.state.authenticationStore.login.logiN_ORGANISATIESAIRID;
      
    await this.Switch("Account");

    this.nameAccount = this.activeObject.accountS_NAAM; 
  }
}
</script>

<style>

</style>