<template>
<nav class="nav justify-content-center mt-5 mb-5">
  <a 
    v-for="tab in tabs" :key="tab" 
    class="badge rounded-pill me-1 mb-1 pt-2 pb-2"
    :class="{'selected': tab.selected,'bg-secondary-soft': !tab.selected}"
    @click="changeTab(tab)">
    {{tab.tab}}
  </a>
</nav>
<div>
  <div v-for="page in pages" :key="page">  
    <div v-show="page.visible" class="mt-5">
      <component v-if="page.params" :is="page.page" :prop="page.params"/>
      <component v-if="!page.params" :is="page.page"/>
    </div>
  </div>
</div>
</template>

<script>
import {shadeColor} from '../../helpers/handleResponse'

export default {
  name: 'Missie',
  props : {
    tabsProp: null
  },
  data:function(){
    return{
      tabs: this.tabsProp.tabs,
      pages: this.tabsProp.pages,
      color: this.tabsProp.color,
      colorDarker:null,
      colorLight:null
    }
  },
  methods:{
    changeTab(e){
      var index = this.tabs.indexOf(e);

      for (let i = 0; i < this.pages.length; i++) {
        if(i != index)
          this.pages[i].visible = false;
        else
        {
          if(this.pages[i].visible)
            this.pages[i].visible = false;
          else
            this.pages[i].visible = true;
        }
      }

      for (let i = 0; i < this.tabs.length; i++) {
        if(i != index)
          this.tabs[i].selected = false;
        else
        {
          if(this.tabs[i].selected)
            this.tabs[i].selected = false;
          else
            this.tabs[i].selected = true;
        }
      }
    }
  },
  created(){
    this.colorDarker = shadeColor(this.color, -40);
    this.colorLight = shadeColor(this.color, 100);
    // this.changeTab(this.tabs[0]);
  }
}
</script>

<style scoped lang="scss">
.badge.bg-secondary-soft {
    background-color: v-bind('colorLight') !important;
    color: v-bind('colorDarker') !important;
    font-size: 1.1rem;
    text-decoration: none;
    cursor: pointer;
}

.bg-secondary-soft:hover{
    background-color: v-bind('color') !important;
    color:white;
}

a{
    color: #335eea;
    text-decoration: none;
}

.selected{
    background-color: v-bind('color') !important;
    color: white;
    text-decoration: none;
    font-size: 1.1rem;
    cursor: pointer;
}
</style>