import { handleResponse } from '../../helpers/handleResponse'
import { Config } from '../../../Config'

export const pandenService = {
    getPanden,
    getPandenOne,
    getPandFromId,
    getLocation,
    getOverTeNemen
};


async function getPanden() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Panden/GetPanden`, requestOptions)
        .then(handleResponse)
        .then(
            panden => { 
                return panden
        });
}

async function getOverTeNemen() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/HomePage/GetOverTeNemen`, requestOptions)
        .then(handleResponse)
        .then(
            panden => { 
                return panden
        });
}



async function getLocation(adress){
    const requestOptions = {
        method: 'GET'
    };

    var googleApi = "AIzaSyCO0mxzdAcaVR1xQw2WEWfnBKATx_8bV08"

    return await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=` + adress + `&key=`+ googleApi, requestOptions)
        .then(handleResponse)
        .then(result => { 
            return result; });
}

async function getPandenOne() {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Panden/GetPandenOne`, requestOptions)
        .then(handleResponse)
        .then(
            panden => { 
                return panden
        });
}

async function getPandFromId(id) {
    const requestOptions = {
        method: 'GET'
    };

    return await fetch(Config.Url() + `/Panden/GetPandFromId?id=`+id, requestOptions)
        .then(handleResponse)
        .then(
            retour => { 
                return retour
        });
}