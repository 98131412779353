<!--
  Acceuille un objet de x1 avec une image un titre un texte (+boutons) et echangeable gauche droite
-->

<template>
    <div class="row align-items-center">

      <div  v-if="!imageRight" class="col-12 col-md-5">
        <img v-if="image" :src="image" class="img-fluid aos-init aos-animate" data-aos="fade-right">
      </div>

      <div :class="{'col-12':true, 'col-md-7':true, 'pe-10': imageRight, 'ps-10': !imageRight}">
        <h1 class="fw-bold mb-3">
          <div data-aos="fade-left" class="aos-init aos-animate">{{title}}</div>
        </h1>

        <p class="aos-init aos-animate" data-aos="fade-left" data-aos-delay="200">
          {{text}}
        </p>
      
      </div>
      
      <div v-if="imageRight" class="col-12 col-md-5">
        <img v-if="image" :src="image" alt="..." class="img-fluid aos-init aos-animate" data-aos="fade-right">
      </div>
    
      <button v-if="button1" @click="$emit('click1')" class="btn btn-secondary">{{button1}}</button>
      <button v-if="button2" @click="$emit('click2')" class="btn btn-secondary">{{button2}}</button>
      <button v-if="button3" @click="$emit('click3')" class="btn btn-secondary">{{button3}}</button>
    </div>
</template>

<script>

export default {
  name: 'Missie',
      props : {
        image: null,
        title: null,
        text: null,
        imageRight: null,
        button1: null,
        button2: null,
        button3: null
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1{
  font-size: 3.2rem;
  line-height: 1.2;
  margin-bottom: 40px;
}
</style>