<template>
<div v-if="producten" id="inschs">
    <Intro 
      @doen="triggerInschr()"
      @nietdoen="triggerStop()"
      @bekijken="triggerList()"
      :cacherNietDoen="cont" 
      :btnNiet="true"
      :btnBekijken="true"
      :btnDoen="true"
      :nomDoen="'Inschrijving insturen'"
      :title="'Inschrijven'"
      :image="producten.actieS_AFBEELDINGTREGISTRATIES"
      :info="producten.actieS_INFOTREGISTRATIES"
      :aanvang="'Voor deze actie kan je van ' + producten.AANVANGTREGISTRATIES + ' tot en met ' + producten.EINDETREGISTRATIES + ' jouw inschrijving insturen.'"
      :endNumbre="total"
      :imageUrl="'SKWINKELNetActieInschrijven'"
      :icon="'SKWINKELNetActieInschrijvenIcoontje'"
    />

    <div v-if="producten.producten && producten.producten.length > 0" id="Kpi">      
      <RegistratiePopup 
        v-if="showRegistratie || showStop" 
        :inschrijving="inschrijving" 
        :actieId="actieId" 
        :actie="producten" 
        :organisatie="log.organisatie"
        @sluiten="onFermeLesFormulairesEtOnScroll()" 
        @continue="inschrOk()"
        @stop="triggerStop()"
        />
      <ProductenPopup
        v-if="showProducten"
        :actieId="actieId" 
        :actie="producten"
        :organisatie="log.organisatie"
        @clicked="onFermeLesFormulairesEtOnScroll()"
        @sluiten="onFermeLesFormulairesEtOnScroll()"
        />
    </div>
    <div v-if="showList" class="mt-10">
      <DeelnemersCommun :actieId ="actieId" :Deelnemers="Deelnemers" :total="total" />
      <div class="text-center mt-7">
        <button type="button" @click="onFermeLesFormulairesEtOnScroll()" class="btn btn-primary">Sluiten</button>
      </div>
    </div>

</div>
</template>

<script>

import Intro from './IntroTables/Intro.vue'
import RegistratiePopup from './Popups/RegistratiePopup.vue'
import DeelnemersCommun from './DeelnemersCommun.vue'
import ProductenPopup from './Popups/ProductenPopup.vue'

export default {
  name: 'Inschrijvingen',
  data:function(){
    return{
      showRegistratie:false,
      showStop:false,
      showProducten:false,
      showList:false,

      inschrijving:true,

      total:0,
      
      cont:false,
      stopped:false
    }
  },
  components:{
      Intro,
      RegistratiePopup,
      DeelnemersCommun,
      ProductenPopup
  },
  props:{
      actieId: null
  },
  methods:{
    //Fermetures
    onFermeLesFormulaires(){
      this.showStop = false;
      this.showRegistratie = false;
      this.showList = false;
      this.showProducten = false;
    },
    onFermeLesFormulairesEtOnScroll(){
      this.onFermeLesFormulaires();
      this.scrollToTop();
    },
    //Ouvertures
    openList(){
      this.onFermeLesFormulaires();
      this.showList = true;
      this.scrollToBottom();
    },
    openInschrijvingen(){
      this.onFermeLesFormulaires();

      if(this.checkDateOk())
      {
        this.inschrijving = true;
        this.showRegistratie = true;
        this.scrollToBottom();
      }
    },
    openStop(){
      this.onFermeLesFormulaires();

      if(this.checkDateOk())
      {
        this.inschrijving = false;
        this.showStop = true;
        this.scrollToBottom();
      }
    },
    openProduits(){
      this.onFermeLesFormulaires();

      if(this.checkDateOk())
      {
        this.showProducten = true;
        this.scrollToBottom();
      }
    },
    //Check date
    checkDateOk(){
      if(new Date(this.producten.actieS_AANVANGTREGISTRATIES) <= Date.now() && new Date(this.producten.actieS_EINDETREGISTRATIES) >= Date.now()){
        if(!this.log)
        {
          this.$router.push('/account');
          return false;
        }
        else
          return true;
       }
      else
        alert(new Date(this.producten.actieS_AANVANGTREGISTRATIES) <= Date.now() ? 
        'De periode voor de inschrijvingen heeft geindigd.' :
        'De periode voor de inschrijvingen is nog niet begonnen.')   
        return false;
    },
    //Actions
    stopOk(){
      this.stopped = true;
      this.OnFermeLesFormulairesEtOnScroll();
    },
    inschrOk(){
      this.cont = true;
      this.openProduits();
    },
    productsOk(){
      this.OnFermeLesFormulairesEtOnScroll();
    },
    triggerList(){
      if(this.showList)
        this.onFermeLesFormulairesEtOnScroll();
      else
        this.openList();
    },
    triggerInschr(){
      if(this.cont){
        if(this.showProducten)
          this.onFermeLesFormulairesEtOnScroll();
        else
          this.openProduits();
      }
      else{
        if(this.showRegistratie)
          this.onFermeLesFormulairesEtOnScroll();
        else
          this.openInschrijvingen();
      }
    },
    triggerStop(){
      if(this.showStop)
        this.onFermeLesFormulairesEtOnScroll();
      else
        this.openStop();
    }, 
    //Scrolls
    scrollToTop(){
        const id = 'inschs';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    scrollToBottom(){
        const id = 'inschs';
        const yOffset = 250; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
  },
  computed:{
    producten(){
      return this.$store.state.actieStore.producten ? this.$store.state.actieStore.producten.items : null;
    },
    Deelnemers(){
        return this.$store.state.actieStore.Deelnemers ? 
          this.$store.state.actieStore.Deelnemers.items : null;
    },
    log(){
      return this.$store.state.authenticationStore.user;
    }  
  },
  async created(){
    await this.$store.dispatch('actieStore/getProducten', this.actieId);
    await this.$store.dispatch('actieStore/getDeelnemers', this.$route.params.id);
  },
  watch:{
    Deelnemers(e){
      if(e)
        e.forEach(element => {
          this.total += element.orgs.length; 
        });
    }
  }
}
</script>

<style scoped>

</style>