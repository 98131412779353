<template>

<div v-if="voorstellen" id="sugs">
  <Intro 
    @bekijken="bekijkenOpenen()"
    @doen="checkOpenen()"
    :nomDoen="'Suggestie insturen'"
    :endNumbre="voorstellen && voorstellen.suggesties ? voorstellen.suggesties.length : null"
    :btnBekijken="true"
    :btnDoen="true"
    :info="voorstellen.actieS_INFOTACTIESUGGESTIES"
    :title="'Suggesties'"
    :image="voorstellen.actieS_AFBEELDINGTACTIESUGGESTIES"
    :aanvang="'Voor deze actie kan je van ' + voorstellen.AANVANGTACTIESUGGESTIES + ' tot en met ' + voorstellen.EINDETACTIESUGGESTIES + ' jouw suggesties insturen.'"
    :imageUrl="'SKWINKELNetActieSuggesties'"
    :icon="'SKWINKELNetActieSuggestiesIcoontje'"
  />
  <VoorstelPopup 
    v-if="openVoorstel" 
    :actieId="actieId" 
    :actie="voorstellen" 
    :organisatie="log.organisatie"
    @sluiten="openVoorstellen()" 
    @clicked="updateVoorstel" /> 

  <section v-if="voorstellen && voorstellen.suggesties && voorstellen.suggesties.length > 0 && openen">
    <div class="container">
          <h3 class="mt-7">Voor deze actie werden {{voorstellen.suggesties.length}} suggesties ingestuurd.</h3>
          <div class="list-group list-group-flush">
              <div v-for="voorstel in aantal" :key="voorstel" class="list-group-item">
                <div class="row align-items-center">
                  <div class="col">
                      <div>
                        <!-- Heading -->
                        <small v-if="voorstellen.suggesties[voorstel - 1].actiesuggestieS_AANGEMAAKT" class="float-start mb-1 me-5">{{voorstellen.suggesties[voorstel - 1].actiesuggestieS_AANGEMAAKT}}</small>
                        <small v-if="!voorstellen.suggesties[voorstel - 1].actiesuggestieS_AANGEMAAKT" class="float-start mb-1 me-5">{{voorstellen.suggesties[voorstel - 1].actiesuggestieS_UPDDAT}}</small>

                        <h3 class="mb-2">{{voorstellen.suggesties[voorstel - 1].actiesuggestieS_ORGANISATIE}}</h3>

                        <small>
                          {{voorstellen.suggesties[voorstel - 1].actiesuggestieS_OMSCHRIJVING}}
                        </small> 
                        <br/>
                        <small>
                          {{voorstellen.suggesties[voorstel - 1].actiesuggestieS_BEVINDING}}
                        </small>
                        
                        <div class="clearfix"></div>
                      </div>
                  </div>
                </div>
              </div>
          </div>


          <div class="text-center mt-7">
            <button v-if="aantal != voorstellen.suggesties.length" type="button" @click="showMore()" class="btn btn-primary">meer actiesuggesties tonen</button>
          </div>
          <div class="text-center mt-7">
            <button type="button" @click="bekijkenOpenen()" class="btn btn-primary">Sluiten</button>
          </div>
    </div>
  </section>
</div>
</template>

<script>
import Intro from './IntroTables/Intro.vue'
import VoorstelPopup from './Popups/VoorstelPopup.vue'

export default {
  name: 'ActieVoorstellen',
  components:{
    Intro,
    VoorstelPopup,
  },
  data: function(){
    return{
      openen:false,
      openVoorstel: false,
      aantal : 0,
      clicked : 0 
    }
  },
  props:{
      actieId: null,
  },
  methods:{
    openForm(){
      this.openVoorstel = true;
      this.openen = false;
    },
    openList(){
      this.openen = true;
      this.openVoorstel = false;
    },
    openVoorstellen(){
      this.openVoorstel = false;
      this.scrollToTop();
    },
    scrollToTop(){
        const id = 'sugs';
        const yOffset = -150; 
        const element = document.getElementById(id);
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({top: y, behavior: 'smooth'});
    },
    bekijkenOpenen(){
      if(!this.openen)
      {
        this.openList();
      }
      else
      {
        this.openen = false;
        this.scrollToTop();
      }
    },
    checkOpenen(){
      if(
        new Date(this.voorstellen.actieS_AANVANGTACTIESUGGESTIES) <= Date.now() &&
        new Date(this.voorstellen.actieS_EINDETACTIESUGGESTIES) >= Date.now())
        {
          if(this.log)
          {
            if(this.openVoorstel)
              this.openVoorstel = false;
            else
              this.openForm();
          }
          else
            this.$router.push('/account')
        }
        else
        alert(new Date(this.voorstellen.actieS_AANVANGTACTIESUGGESTIES) <= Date.now() ? 
        'Je kan geen suggestie meer insturen voor de deze actie.' :
        'Je kan nog geen suggestie insturen voor deze actie.')    },
    updateVoorstel(){
      this.openVoorstel = false;
      this.$store.dispatch('actieStore/getVoorstellen', this.actieId);
      this.scrollToTop();  
    },
    showMore(){
      this.clicked++;
      this.update();
    },
    update(){
      if(!this.voorstellen)
        this.aantal = 0;
      else
      {
        var enPlus = 5 * this.clicked;
        var afficher = 5 + enPlus;
        this.aantal = this.voorstellen.suggesties.length < afficher ? this.voorstellen.suggesties.length : afficher;
      }
    }
  },
  computed:{
    voorstellen(){
      return this.$store.state.actieStore.voorstellen ? this.$store.state.actieStore.voorstellen.items : null;
    },
    log(){
      return this.$store.state.authenticationStore.user;
    }    
  },
  async created(){
    this.$store.dispatch('actieStore/getVoorstellen', this.actieId);
  },
  watch:{
    voorstellen(){
      this.update()
    }
  }
}
</script>

<style scoped>
img{
  width:25px;
  height: 25px;;
}

li{
  text-decoration: none;
}

ul{
  list-style-type: none;
  margin:0;
}

h1{
    font-weight: 900;
}

.titre{
  color:#5ca2be;
}

.list-enter-from{
  opacity:0;
  transform:scale(0);
}

.list-enter-to{
  opacity:1;
  transform:scale(1);
}

.list-enter-active{
  transition:all 0.4s ease;
}

.voors-enter-from,.voors-leave-to{
  opacity:0;
}

.voors-enter-to,.voors-leave-from{
  opacity:1;
}

.voors-enter-active, .voors-leave-active{
  transition:all 0.4s ease-out;
}
</style>