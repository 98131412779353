import { pandenService } from '../../services/Panden/panden.service'
import { getDate_ddmmyyyy } from '../../helpers/handleResponse'

export const pandenStore = {
    namespaced: true,
    state: () => ({
        panden: null,
        pandenOne: null,
        pandFromId: null,
        overTeNemen:null,
    }),
    actions: {
        async getPanden({ commit }){
            await pandenService.getPanden()
            .then(
                panden => commit('getPandenSuccess', panden),
                error => commit('getPandenFailure', error)
            )
        },
        async getOverTeNemen({ commit }){
            await pandenService.getOverTeNemen()
            .then(
                data => commit('getOverTeNemenSuccess', data),
                error => commit('getOverTeNemenFailure', error)
            )
        },
        async getPandenOne({ commit }){
            await pandenService.getPandenOne()
            .then(
                panden => commit('getPandenOneSuccess', panden),
                error => commit('getPandenOneFailure', error)
            )
        },
        async getPandFromId({ commit, dispatch }, id){            
            await pandenService.getPandFromId(id)
            .then(
                retour => {
                    commit('getPandFromIdSuccess', retour);
                    
                    if(retour && retour.bedrijfsruimteS_BEDRIJFSRUIMTE)
                        dispatch('getLocation', retour.bedrijfsruimteS_BEDRIJFSRUIMTE);
                },
                error => commit('getPandFromIdFailure', error)
            )
        },
        async getLocation({commit}, adress){
            await pandenService.getLocation(adress)
            .then(
                retour => commit('getLocationSuccess', retour),
                error => commit('getLocationFailure', error)
            )
        }
    },
    mutations: {
        getPandenSuccess(state, panden){
            state.panden = { items: panden };
        },
        getPandenFailure(state, error) {
            state.panden = { error };
        },
        getPandenOneSuccess(state, panden){
            state.pandenOne = { items: panden };
        },
        getPandenOneFailure(state, error) {
            state.pandenOne = { error };
        },        
        getPandFromIdSuccess(state, retour){
            state.pandFromId = retour;

            if(state.pandFromId)
                state.pandFromId.bedrijfsruimteS_BESCHIKBAAR = getDate_ddmmyyyy(new Date(state.pandFromId.bedrijfsruimteS_BESCHIKBAAR));                
        },
        getPandFromIdFailure(state, error) {
            state.pandFromId = error;
        },
        getLocationSuccess(state, retour){
            if(state.pandFromId && retour)
                state.pandFromId.location = retour.results[0].geometry.location;
        },
        getLocationFailure(state, error) {
            if(state.pandFromId)
                state.pandFromId.location = error;
        },
        getOverTeNemenSuccess(state, data){
            state.overTeNemen = { items: data };
        },
        getOverTeNemenFailure(state, error) {
            state.overTeNemen = { error };
        }
        
    }
  }