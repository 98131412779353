<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-light fixed">
    <div class="container">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarTogglerDemo03">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/' }">SKWINKEL</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/organisaties' }">SKWINKELS</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/start' }">Start je SKWINKEL</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'actie', params:{ id:'recdWFy3BpTs67HLx'} }">SKWINKELActie </router-link>
          </li>
        </ul>
        <form class="d-flex" v-if="!log">
          <router-link class="nav-link" :to="{ path: '/account' }"><button class="btn btn-sm btn-secondary me-2" type="button">Inloggen</button></router-link>
          <!-- <router-link class="nav-link" :to="{ path: '/request' }"><button class="btn btn-sm btn-outline-secondary" type="button">Inschrijven</button></router-link> -->
        </form>
        <form class="d-flex" v-if="log">
          <button @click="Logout" class="btn btn-sm btn-secondary me-2" type="button">Uitloggen</button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'Navigation',
  data:function(){
    return{
      logged: null,
    }
  },
  methods:{
    async Logout(){
      await this.$store.dispatch('authenticationStore/logout');
    }
  },
  computed:{
    log(){
      return this.$store.state.authenticationStore.user;
    }    
  }
}
</script>

<style scoped>

.navbar{
  box-shadow:0px 5px 6px rgba(0, 0, 0, 0.3);
  height: 55px !important;
}

a{
  cursor: pointer;
}

</style>