<template>
  <div class="text-center tumiZ">
    <div class="d-grid gap-2">
      <button type="button" @click="openModal" class="btn btn-danger">{{buttonMsg}}</button>
    </div>
  </div>

  <transition tag="voors" name="voors">
    <div v-show="modal" class="back mt-10 pt-10 pb-10">
      <div class="container">
        <div class="modTumi" ref="intDiv" :style="[thanks ? {'height': hauteurDiv + 'px'} : {}]">
          <div v-if="thanks" class="text-center" :style="{'padding-top': hauteurDiv / 2 - 20 + 'px'}">
            {{thanks}}
          </div>

          <div v-if="!thanks">
            <div class="mb-5 text-start">
              <h2>{{formData.title}}</h2>
              <p>{{formData.text}}</p>
            </div>

            <template v-for="input in formData.fields" :key="input">
              
              <input 
                v-if="!input.textarea"
                type="text" 
                class="form-control mb-5"
                v-model="input.model"
                :placeholder="input.rempli ? input.placeholder : input.required"
                :style="{'border': input.rempli ? '1px solid white' : '2px solid red',}">

              <textarea 
                v-if="input.textarea"
                v-model="input.model"
                class="form-control mb-5"
                :placeholder="input.rempli ? input.placeholder : input.required"
                :style="{'border': input.rempli ? '1px solid white' : '2px solid red',}">
              </textarea>

            </template>

            <button type="button" @click="fermerModal" class="btn btn-danger float-start">Sluiten</button>
            <button type="button" @click="insturen" class="btn btn-danger float-end">Insturen</button>

            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {shadeColor} from '../../helpers/handleResponse'
import {delay} from '../../helpers/handleResponse'
import {Form} from '../../helpers/Classes'

export default {
  name: 'Formulier',
  props:{
    form : Form
  },
  emits: ["insturen","sluiten"],
  data: function(){
    return{
      modal: false,
      formData: this.form,
      buttonMsg: this.form.buttonMsg,
      colorLight: null,
      color: this.form.color,
      colorDarker: null,
      thanks:"",

      hauteurDiv: 0,
      guid:null
    }
  },
  computed:{
    log(){
      return this.$store.state.authenticationStore.user;
    }   
  },
  methods:{
    ouvrirModal(){
      this.modal = true;
      setTimeout(() => this.scrollToBottom(), 250);
    },
    fermerModal(){
      this.scrollToTop();
      setTimeout(() => this.modal = false, 250);
    },
    closePopup(){
      this.fermerModal();
    },
    openModal(){
      if(this.modal)
        this.fermerModal();
      else{
        if(this.log)
          this.ouvrirModal();
        else
          this.$router.push('/account')
      }
    },
    async insturen(){
      var onPasse = true;

      this.formData.fields.forEach(element => {
        if(element.required)
        {
          if(element.model)
            element.rempli = true;
          else
          {
            element.rempli = false;
            onPasse = false;
          }
        }
      });

      if(!onPasse)
        return;

      this.hauteurDiv = this.$refs.intDiv.clientHeight;

      this.thanks = this.formData.thanks;

      this.$emit('insturen', this.formData);

      await delay(2);

      this.fermerModal();
    },
    scrollToTop(){

        const yOffset = -800; 
        const element = this.$refs.intDiv;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        
        window.scrollTo({top: y, behavior: 'smooth'});
    },
    scrollToBottom(){
        const yOffset = -100;
        const element = this.$refs.intDiv;
        const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
        
        window.scrollTo({top: y, behavior: 'smooth'});
    }
  },
  created(){
    if(this.formData)
      this.formData.fields.forEach(element => {
        element.rempli = true;
      });

    this.colorDarker = shadeColor(this.color, -40);
    this.colorLight = shadeColor(this.color, -20);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.voors-enter-from,.voors-leave-to{
  opacity:0;
}

.voors-enter-to,.voors-leave-from{
  opacity:1;
}

.voors-enter-active, .voors-leave-active{
  transition:all 0.4s ease-out;
}

.back{
  background: v-bind('color') !important;
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
}

.btn-danger{
  background-color: v-bind('colorLight') !important;
  border-color: v-bind('colorLight') !important;
}

.btn-danger:hover{
  background-color: v-bind('colorDarker') !important;
  border-color: v-bind('colorDarker') !important;
}

.tumiZ{
    z-index: 1000;
}
</style>