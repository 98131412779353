import { createRouter, createWebHashHistory } from 'vue-router'
import {Config} from '../../Config'

import Home from '../views/Home/Home.vue'
import Project from '../views/Projecten/Project.vue'
import Projecten from '../views/Projecten/Projecten.vue'
import Doelstelling from '../views/Doelstellingen/Doelstelling.vue'
import Panden from '../views/Panden/Panden.vue'
import PandOne from '../views/Panden/PandOne.vue'
import Actie from '../views/Acties/Actie.vue'
import Start from '../views/Start/Start.vue'
import AllActies from '../views/Acties/AllActies.vue'
import Portal from '../views/Portal/Portal.vue'
import Organisaties from '../views/Organisaties/Organisaties.vue'
import Account from '../views/Portal/Account.vue'
import NewPass from '../views/Portal/NewPass.vue'

const routes = [
  {
    path: '/',
    component: Home,
    name: Home
  },
  {
    path: '/allActies',
    name: 'AllActies',
    component: AllActies
  },
  {
    path: '/pandOne/:id',
    name:'pandOne',
    component: PandOne
  },
  {
    path: '/doelstelling/:id',
    name:'doelstelling',
    component: Doelstelling
  },
  {
    path: '/projecten',
    name:'projecten',
    component: Projecten
  },
  {
    path: '/start',
    name:'start',
    component: Start
  },
  {
    path: '/panden',
    name:'panden',
    component: Panden
  },
  {
    path: '/project/:id',
    name:'project',
    component: Project

  },
  {
    path: '/actie/:id',
    name:'actie',
    component: Actie

  },
  {
    path: '/organisaties',
    name: 'organisaties',
    component: Organisaties
  },
  //PORTAL
  {
    path: '/portal',
    name: 'portal',
    component: Portal
  },
  {
    path: '/account/:guid',
    name: 'account',
    component: Account,
    meta: {
      hideNavbar: true,
     }
  },
  {
    path: '/newPass/:guid',
    name: 'newPass',
    component: NewPass,
    meta: {
      hideNavbar: true,
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const nonPublicPages = ['/portal'];
  const authRequired = nonPublicPages.includes(to.path.toLowerCase());
  const loggedIn = localStorage.getItem('user');
  
  //si on va vers le portal et qu'on a le parametre logged
  if(to.path.toLowerCase() == '/portal' && to.query && to.query.logged)
    //et qu'on ne vient pas de de l'enregistrement
    if(from.path.toLowerCase() == '/account')
      //on retourne a la page don't on vient
      return next(Config.GetLoginRoute()[0].path);

  var LoginRoute = [];
  LoginRoute.push(from, to);
  
  Config.SetLoginRoute(LoginRoute);

  if (authRequired && !loggedIn) {
    return next('/account');
  }

  next();
})

export default router


