export class FieldForm{
    constructor(model, placeholder, required, textarea){
        this.model = model;
        this.placeholder = placeholder;
        this.required = required;
        this.textarea = textarea;
    }
}

export class Form{
    constructor(dispatch, buttonMsg, fields, thanks, title, text, color){
        this.dispatch = dispatch;
        this.buttonMsg = buttonMsg;
        this.fields = fields;
        this.thanks = thanks;
        this.title = title;
        this.text = text;
        this.color = color;
    }
}

export class RaadItem{
    constructor(aanvang, einde, benaming, airid){
        this.aanvang = aanvang;
        this.einde = einde;
        this.benaming = benaming;
        this.airid = airid;
    }
}

export class RaadItems{
    constructor(items, inschrijven, insturen){
        this.items = items;
        this.inschrijven = inschrijven;
        this.insturen = insturen;
    }
}

export class RaadClass{
    constructor(title, text, items){
        this.title = title;
        this.text = text;
        this.RaadItems = items;
    }
}