<template>
    <div class="pt-10 pb-10">
      <div class="container">
        <Missie v-if="voorstelling && voorstelling.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(voorstelling.rubriekeN_THEMABEELDENT600X600)[0]" :text="voorstelling.rubriekeN_TEKST" :title="voorstelling.rubriekeN_TITEL"/>
      </div>
    </div>

    <div class="pt-10 pb-10 tumiBackground">
      <div class="container">
        <Missie class="pb-5" :imageRight="true" v-if="aanpak && aanpak.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(aanpak.rubriekeN_THEMABEELDENT600X600)[0]" :text="aanpak.rubriekeN_TEKST" :title="aanpak.rubriekeN_TITEL"/>
        <Tabs :tabsProp="tabsDoelsteling" />
      </div>
    </div>

    <div v-if="tabsWerking" class="pt-10 pb-10">
      <div class="container">
        <Missie class="pb-5" v-if="werking && werking.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(werking.rubriekeN_THEMABEELDENT600X600)[0]" :text="werking.rubriekeN_TEKST" :title="werking.rubriekeN_TITEL"/>
        <Tabs :tabsProp="tabsWerking" />
      </div>
    </div>

    <div class="pt-10 pb-10 tumiBackground">
      <div class="container">
        <Missie class="pb-5" :imageRight="true" v-if="panden && panden.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(panden.rubriekeN_THEMABEELDENT600X600)[0]" :text="panden.rubriekeN_TEKST" :title="panden.rubriekeN_TITEL"/>
        <Ruimtes v-if="ruimtes" :ruimtes="ruimtes"/>
      </div>
    </div>

    <div v-if="tabsPanden" class="pt-10 pb-10">
      <div class="container">
        <Missie class="pb-5" v-if="aquisities && aquisities.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(aquisities.rubriekeN_THEMABEELDENT600X600)[0]" :text="aquisities.rubriekeN_TEKST" :title="aquisities.rubriekeN_TITEL"/>
        <Tabs :tabsProp="tabsPanden"/>
      </div>
    </div>

    <div v-if="formActies" class="pt-10 pb-10 tumiBackground">
      <div class="container">
        <Missie class="pb-5" :imageRight="true" v-if="acties && acties.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(acties.rubriekeN_THEMABEELDENT600X600)[0]" :text="acties.rubriekeN_TEKST" :title="acties.rubriekeN_TITEL"/>
        <Formulier :form="formActies"/>
        <Acties class="mt-5"/>
        <div class="text-center">
          <router-link :to="{ name: 'AllActies'}" class="btn btn-secondary">Klik hier voor een overzicht van alle acties.</router-link>
        </div>
      </div>
    </div>

    <div v-if="formContact" class="pt-10 pb-10">
      <div class="container">
        <Missie class="pb-5" v-if="contact && contact.rubriekeN_THEMABEELDENT600X600" :image="JSON.parse(contact.rubriekeN_THEMABEELDENT600X600)[0]" :text="contact.rubriekeN_TEKST" :title="contact.rubriekeN_TITEL"/>
        <Formulier :form="formContact"/>
      </div>
    </div>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { shallowRef } from 'vue'

import Tabs from '../../components/Widgets/Tabs.vue'
import BannerPanden from '../../components/Home/BannerPandenHome.vue'
import Missie from '../../components/Widgets/Missie.vue'
import Doelstellingen from '../Doelstellingen/Doelstelling.vue'
import Waarden from '../Doelstellingen/Waarden.vue'
import Team from '../../components/Home/Team.vue'
import RaadDyn from '../../components/Widgets/Raad.vue'
import Formulier from '../../components/Widgets/Formulier.vue'
import Ruimtes from '../../components/Home/Ruimtes/Ruimtes.vue'
import Panden from '../../components/Home/Panden/Panden.vue'
import OverTeNemens from '../../components/Home/Panden/OverTeNemens.vue'
import Acties from '../../components/Home/Acties/Acties.vue'

import { RaadClass } from '../../helpers/Classes'
import { Form } from '../../helpers/Classes'
import { FieldForm } from '../../helpers/Classes'

export default {
  name: 'Home',
  components: {
    Missie,
    Tabs,
    BannerPanden,
    Doelstellingen,
    Waarden,
    Team,
    RaadDyn,
    Formulier,
    Ruimtes,
    Panden,
    OverTeNemens,
    Acties
  },
  data:function(){
    return{
      voorstelling: null,
      aanpak: null,
      werking: null,
      panden: null,
      ruimtes: null,
      aquisities: null,
      acties: null,
      contact: null,

      tabsDoelsteling:
      {
        tabs:[{ tab: "Doelstellingen", selected: false },{ tab: "Waarden", selected: false }],
        pages:[{ page: shallowRef(Doelstellingen), visible:false, params:{} },{ page: shallowRef(Waarden), visible:false, params:{} }],
        color:"#5ea7c4"
      },
      tabsWerking: null,
      tabsPanden:null,
      
      raden: null,
      ledenRaden: null,
      algVerg: null,

      formActies:null,
      formContact:null
    }
  },
  computed:{
    log(){
      return this.$store.state.authenticationStore.user;
    }
  },
  methods:{
    getRaden(){
      var home = this.$store.state.homeStore.home.rubrieken;
      var formulieren = this.$store.state.homeStore.home.formulieren;
      //var themas = this.$store.state.homeStore.home.themats;

      var kandidatuur = formulieren.find(i => i.formuliereN_AIRID == "reccwsj1ohmcvmEQi");      
      var team = home.find(i => i.rubriekeN_AIRID == "recR5ndn2V2N32BzF");

      var raden = new RaadClass( 
        this.$store.state.raadStore.raden.rubriekeN_TITEL, 
        this.$store.state.raadStore.raden.rubriekeN_TEKST, 
        this.$store.state.raadStore.raden.raden);

      var ledenRaden = new RaadClass(
        this.$store.state.raadStore.ledenRaden.rubriekeN_TITEL, 
        this.$store.state.raadStore.ledenRaden.rubriekeN_TEKST,  
        this.$store.state.raadStore.ledenRaden.raden);

      var algVerg = new RaadClass(
        this.$store.state.raadStore.algVerg.rubriekeN_TITEL, 
        this.$store.state.raadStore.algVerg.rubriekeN_TEKST, 
        this.$store.state.raadStore.algVerg.raden);

      this.tabsWerking = {
        tabs:[
          { tab: "SKWINKELTEAM", selected: false },
          { tab: "SKWINKELRADEN",selected: false },
          { tab: "OPEN SKWINKEL",selected: false },
          { tab: "SKWINKELMEETING",selected: false }
        ],
        pages:[
          { page: shallowRef(Team), visible:false, params:{
            "title":kandidatuur.formuliereN_FORMULIER, "text":kandidatuur.formuliereN_TEKST, 
            "titleTeam": team.rubriekeN_TITEL, "textTeam": team.rubriekeN_TEKST
            } },

          { page: shallowRef(RaadDyn), visible:false, params:{
              "raad" : raden
            } },
          { page: shallowRef(RaadDyn), visible:false, params:{
              "raad" : ledenRaden
            } },
          { page: shallowRef(RaadDyn), visible:false, params:{
              "raad" : algVerg
            } }],
        color:"#5ea7c4"
      };

      this.tabsPanden = {
        tabs:[
          {tab:"Te huur/koop", selected:false},
          {tab:"Over te nemen", selected:false}
        ],
        pages:[
          { page: shallowRef(Panden), visible:false, params:{} },
          { page: shallowRef(OverTeNemens), visible:false, params:{} },
        ],
        color:"#5ea7c4"
      };
    },
    getIntros(){
      var home = this.$store.state.homeStore.home.rubrieken;

      this.voorstelling = home.find(i => i.rubriekeN_AIRID == "recAhEMPUghDg5jcC");
      this.aanpak = home.find(i => i.rubriekeN_AIRID == "recz0FTd4k2Jpx72n");
      this.werking = home.find(i => i.rubriekeN_AIRID == "recEJTFJKNQXBk46v");
      this.panden = home.find(i => i.rubriekeN_AIRID == "rectsvckA5lgMNun3");
      this.aquisities = home.find(i => i.rubriekeN_AIRID == "recgA6Z7SadTpqRKU");
      this.acties = home.find(i => i.rubriekeN_AIRID == "rec0m5RV6wmxHJsb1");
      this.contact = home.find(i => i.rubriekeN_AIRID == "rec7cPtSX1MVfhszC");
    },
    getActiesForm(){
      var formulieren = this.$store.state.homeStore.home.formulieren;
      var textActie = formulieren.find(e => e.formuliereN_AIRID == "recEGHrrJUsmKrHxH");

      this.formActies = new Form(
        "sendActies",
        "Actievoorstelformulier",
        [
          new FieldForm(this.log ? this.log.organisatie.organisatieS_NAAM : "", "Naam organisatie", "", false),
          new FieldForm("", "Vul hier een titel voor jouw actievoorstel in", "", false),
          new FieldForm("", "Jouw actievoorstel.", "Actievoorstel is een verplicht veld", true)
        ], 
        "Bedankt voor jouw voorstel. Je wordt eerstdaags gecontacteerd.", 
        textActie.formuliereN_FORMULIER, 
        textActie.formuliereN_TEKST,
        "#5FA7C4"
      );
    },
    getContactForm(){
      var formulieren = this.$store.state.homeStore.home.formulieren;
      var textContact = formulieren.find(e => e.formuliereN_AIRID == "recEZTUAYgUcqaHEG");

      this.formContact = new Form(
        "sendContact",
        "Contactformulier",
        [
          new FieldForm(this.log ? this.log.organisatie.organisatieS_NAAM : "", "Naam organisatie", "", false),
          new FieldForm("", "Onderwerp", "Onderwerp is een verplicht veld.", false),
          new FieldForm("", "Vraag", "Vraag is een verplicht veld.", true)
        ], 
        "Bedankt voor jouw vraag. Je wordt eerstdaags gecontacteerd.", 
        textContact.formuliereN_FORMULIER, 
        textContact.formuliereN_TEKST,
        "#5FA7C4"
      );
    },
    getPanden(){
      this.ruimtes = this.$store.state.homeStore.panden;
    }
  },
  async created(){
    await this.$store.dispatch('homeStore/getHome');

    this.getIntros();
    this.getActiesForm();
    this.getContactForm();
    this.getPanden();

    await this.$store.dispatch('raadStore/getBestuurRaden');
    await this.$store.dispatch('raadStore/getLedenRaden');
    await this.$store.dispatch('raadStore/getAlgVerg');

    this.getRaden();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>